import React, { useEffect, useRef } from 'react';
import {
  faComments as falComments,
  faEnvelope as falEnvelope,
  faPhone as falPhone } from '@fortawesome/pro-light-svg-icons';
import { faSortUp as fasSortUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ContactPhones from './ContactPhones';
import ContactWhatsApp from './ContactWhatsApp';
import DropdownArrow from './DropdownArrow';
import SearchIcon from "./SearchIcon";
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../../helpers/MethodHelper";
import Checkers from "../../../helpers/class/Checkers";

export default function ContactIcons ( props ) {

  const phoneRef = useRef();
  const chatRef = useRef();
  const mailRef = useRef();
  const addEventListeners = ( element ) => {
    if ( element.children.length > 1 ) {
      element.addEventListener( 'mouseenter', function () {
        this.children[0].classList.add( 'show' );
        this.children[0].setAttribute( 'aria-extended', 'true' );
        this.children[1].classList.add( 'show' );
      });
      element.addEventListener( 'mouseleave', function () {
        this.children[0].classList.remove( 'show' );
        this.children[0].setAttribute( 'aria-extended', 'false' );
        this.children[1].classList.remove( 'show' );
      });
    }
  }

  useEffect( () => {
    if ( document !== undefined ) {
      if ( phoneRef.current ) {
        addEventListeners( phoneRef.current );
      }
      if ( chatRef.current ) {
        addEventListeners( chatRef.current );
      }
      if ( mailRef.current ) {
        addEventListeners( mailRef.current );
      }
    }
  }, []);

  const menuLabel = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_menu_label_start')
  );

  return (
    <>
      {
        props.contactIcons != null && Checkers.isValidString(menuLabel) ?
            <>
              <li className={'nav-label nav-item'}>
                <span className={'p-r-10 fs-16 bold nav-link pointer'}>{menuLabel}</span>
              </li>
            </>
            :
            <>
            </>
      }
      {
          props.contactIcons != null ?
              <>
                {
                  props.contactIcons.phoneIcon == true &&
                  props.contactIcons.phoneIconData != null &&
                  <li
                      ref={phoneRef}
                      className={
                        props.contactIcons.phoneIconData != null ?
                            props.contactIcons.phoneIconData.split( ',' ).length > 1 ?
                                'nav-item dropdown cursor-pointer me-0'
                                :'nav-item me-0'
                            :'nav-item me-0'
                      }
                  >
                    <a
                        target="_blank"
                        id={
                          props.hasOwnProperty('modalDropdownClass') ?
                              props.modalDropdownClass != null ?
                                  'modal-phone-icon'
                                  : 'phone-icon'
                              : 'phone-icon'
                        }
                        href={
                          props.contactIcons.phoneIconData != null ?
                              props.contactIcons.phoneIconData.split(',').length > 1 ?
                                  '#'
                                  : 'tel:' + props.contactIcons.phoneIconData.replace(/\s+/g, '')
                              : '#'
                        }
                        className="nav-link d-flex align-items-center" aria-label="phone" title="phone" rel="noreferrer"
                    >
                      <span className="sr-only">phone</span>
                      <FontAwesomeIcon icon={falPhone} size="2x"/>
                    </a>

                    {
                    props.contactIcons.phoneIconData != null &&
                        props.contactIcons.phoneIconData.split( ',' ).length > 1 &&
                        <ul
                            className={`Contact-Icons__dropdown 
                          ${props.hasOwnProperty( 'modalDropdownClass' ) && props.modalDropdownClass != null ? 'dropdown-menu dropdown-article-menu\ ' + props.modalDropdownClass : ''} dropdown-menu dropdown-article-menu shadow
                        `}
                            aria-labelledby={
                              props.hasOwnProperty( 'modalDropdownClass' ) ?
                                  props.modalDropdownClass != null ?
                                      'modal-phone-icon'
                                      : 'phone-icon'
                                  : 'phone-icon'
                            }
                            style={
                              props.hasOwnProperty( 'modalDropdownClass' ) && props.modalDropdownClass != null ?
                                  { bottom: '80px', boxShadow: '0 0 10px #ccc!important' }
                                  : undefined
                            }
                        >
                          {props.hasOwnProperty( 'modalDropdownClass' ) && props.modalDropdownClass != null ?
                              <li style={
                                props.side == 'right' ?
                                    { position: 'fixed', bottom: '77.2px', left: 'calc(25% - 16px)' }
                                    : { position: 'fixed', bottom: '77.2px', right: 'calc(78% - 25px)' }
                              }>
                                <FontAwesomeIcon icon={fasSortUp}/>
                              </li>
                              : <DropdownArrow/>
                          }
                          <ContactPhones phoneIconData={props.contactIcons.phoneIconData}/>
                        </ul>
                    }
                  </li>
              }
                {
                  props.contactIcons.chatIcon == true ?
                      <li
                          ref={chatRef}
                          className={
                            props.contactIcons.chatIconData != null ?
                                props.contactIcons.chatIconData.split( ',' ).length > 1 ?
                                    'nav-item dropdown cursor-pointer me-0'
                                    :'nav-item me-0'
                                :'nav-item me-0'
                          }
                      >
                        <a
                            target="_blank"
                            id={
                              props.hasOwnProperty('modalDropdownClass') ?
                                  props.modalDropdownClass != null ?
                                      'modal-chat-icon'
                                      : 'chat-icon'
                                  : 'chat-icon'
                            }
                            href={
                              props.contactIcons.chatIconData != null ?
                                  props.contactIcons.chatIconData.split(',').length > 1 ?
                                      '#'
                                      : 'https://wa.me/' + props.contactIcons.chatIconData.replace(/\s+/g, '').replace(/\+/g, "")
                                  : '#'
                            }
                            className="nav-link d-flex align-items-center" aria-label="chat" title="chat"
                            rel="noreferrer"
                        >
                          <span className="sr-only">chat</span>
                          <FontAwesomeIcon icon={falComments} size="2x"/>
                        </a>

                        {
                          props.contactIcons.chatIconData != null ?
                              props.contactIcons.chatIconData.split( ',' ).length > 1 ?
                                  <ul
                                      className={`Contact-Icons__dropdown 
                          ${props.hasOwnProperty( 'modalDropdownClass' ) && props.modalDropdownClass != null ? 'dropdown-menu dropdown-article-menu\ ' + props.modalDropdownClass : ''} dropdown-menu dropdown-article-menu shadow
                        `}
                                      aria-labelledby={
                                        props.hasOwnProperty( 'modalDropdownClass' ) ?
                                            props.modalDropdownClass != null ?
                                                'modal-chat-icon'
                                                : 'chat-icon'
                                            : 'chat-icon'
                                      }
                                      style={
                                        props.hasOwnProperty( 'modalDropdownClass' ) && props.modalDropdownClass != null ?
                                            { bottom: '80px', left: '20%', boxShadow: '0 0 3px #ccc' }
                                            : undefined
                                      }
                                  >
                                    {props.hasOwnProperty( 'modalDropdownClass' ) && props.modalDropdownClass != null ?
                                        <li style={
                                          props.side == 'right' ?
                                              { position: 'fixed', bottom: '77.2px', right: 'calc(40% - 16px)' }
                                              : { position: 'fixed', bottom: '77.2px', right: 'calc(50% - 18px)' }
                                        }>
                                          <FontAwesomeIcon icon={fasSortUp}/>
                                        </li>
                                        : <DropdownArrow/>
                                    }
                                    <ContactWhatsApp phoneIconData={props.contactIcons.chatIconData}/>
                                  </ul>
                                  : ''
                              : ''
                        }
                      </li>
                      :''
                }
                {
                  props.contactIcons.mailIcon == true ?
                      <li
                          ref={mailRef}
                          className={
                            props.contactIcons.mailIconData != null ?
                                props.contactIcons.mailIconData.split( ',' ).length > 1 ?
                                    'nav-item dropdown cursor-pointer me-0'
                                    :'nav-item me-0'
                                :'nav-item me-0'
                          }
                      >
                        <a
                            target="_blank"
                            id={
                              props.hasOwnProperty('modalDropdownClass') ?
                                  props.modalDropdownClass != null ?
                                      'modal-mail-icon'
                                      : 'mail-icon'
                                  : 'mail-icon'
                            }
                            href={
                              props.contactIcons.mailIconData != null ?
                                  props.contactIcons.mailIconData.split(',').length > 1 ?
                                      '#'
                                      : 'mailto:' + props.contactIcons.mailIconData.replace(/\s+/g, '')
                                  : '#'
                            }
                            className="nav-link d-flex align-items-center" aria-label="mail" title="mail"
                            rel="noreferrer"
                        >
                          <span className="sr-only">mail</span>
                          <FontAwesomeIcon icon={falEnvelope} size="2x"/>
                        </a>

                        {
                          props.contactIcons.mailIconData != null ?
                              props.contactIcons.mailIconData.split( ',' ).length > 1 ?
                                  <ul
                                      className={`Contact-Icons__dropdown 
                          ${props.hasOwnProperty( 'modalDropdownClass' ) && props.modalDropdownClass != null ? 'dropdown-menu dropdown-article-menu\ ' + props.modalDropdownClass : ''} dropdown-menu dropdown-article-menu shadow
                        `}
                                      aria-labelledby={
                                        props.hasOwnProperty( 'modalDropdownClass' ) ?
                                            props.modalDropdownClass != null ?
                                                'modal-mail-icon'
                                                : 'mail-icon'
                                            : 'mail-icon'
                                      }
                                      style={
                                        props.hasOwnProperty( 'modalDropdownClass' ) && props.modalDropdownClass != null ?
                                            { bottom: '80px', left: '25%', boxShadow: '0 0 10px #ccc!important' }
                                            : undefined
                                      }
                                  >
                                    {props.hasOwnProperty( 'modalDropdownClass' ) && props.modalDropdownClass != null ?
                                        <li style={
                                          props.side == 'right' ?
                                              { position: 'fixed', bottom: '77.2px', right: '32px' }
                                              : { position: 'fixed', bottom: '77.2px', right: 'calc(23% - 7.2px)' }
                                        }>
                                          <FontAwesomeIcon icon={fasSortUp}/>
                                        </li>
                                        : <DropdownArrow/>
                                    }

                                    {props.contactIcons.mailIconData.split( ',' ).map( ( mail, key ) => (
                                        // eslint-disable-next-line react/jsx-key
                                        <li className={key == 0 ? 'dropdown-ul-item mt-20px' : 'dropdown-ul-item mt-3'} key={key}>
                                          <a className="dropdown-item text-wrap"
                                             target="_blank"
                                             href={
                                               mail.split( ':' ).length > 1 ?
                                                   'mailto:' +  mail.split( ':' )[1].replace( /\s+/g, '' )
                                                   :
                                                   'mailto:' +  mail.replace( /\s+/g, '' )
                                             } rel="noreferrer">
                                            {
                                              mail.split( ':' ).length > 1 ?
                                                  mail.split( ':' )[0].replace( /\s+/g, '' )
                                                  :
                                                  mail.replace( /\s+/g, '' )
                                            }
                                          </a>
                                        </li>
                                    ) )}
                                  </ul>
                                  : ''
                              : ''
                        }
                      </li>
                      :''
                }
              </>
              :
              <></>
      }
      {/*{*/}
      {/*    showSearch == '1' ?*/}
      {/*        <>*/}
      {/*          <SearchIcon />*/}
      {/*          </>*/}
      {/*        :*/}
      {/*        <></>*/}
      {/*}*/}
    </>
  )
}
