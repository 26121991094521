import * as React from 'react';

import ContactForm from './contact/ContactForm';
import ContactLocations from './contact/ContactLocations';
import ContactSkeleton from './Skeletons/ContactSkeleton';
import ViewPort from './ViewPort';
import {useEffect} from "react";
import {faFacebookF, faInstagram, faYoutube, faTwitter, faWhatsapp, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../helpers/MethodHelper";
import Checkers from "../helpers/class/Checkers";
import {Alert} from "@mui/lab";

export default function Contact ( props ) {

  const isSuccess = props.isSuccess != undefined ? props.isSuccess : false

  const showContactForm = props.showContactForm != undefined ? props.showContactForm : true

  const contactPhoneOverride = props.contactPhoneOverride != undefined ? props.contactPhoneOverride : null

  const contactEmailOverride = props.contactEmailOverride != undefined ? props.contactEmailOverride : null

  const contactTitleOverride = props.contactTitleOverride != undefined ? props.contactTitleOverride : null

  const contactPhone = props.hasOwnProperty('contactHtml') && props.contactHtml.hasOwnProperty('contact_phone') && props.contactHtml.contact_phone != null ? props.contactHtml.contact_phone : null

  const contactEmail = props.hasOwnProperty('contactHtml') && props.contactHtml.hasOwnProperty('contact_email') && props.contactHtml.contact_email != null ? props.contactHtml.contact_email : null

  const showSocial = useSelector(
      state => Checkers.valueToBoolean(fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_general_show_social'))
  );

  const builderSettings = useSelector(
      state => state['builderSettingsData']
  );

  const facebookLink = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_general_show_social_facebook')
  );

  const instagramLink = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_general_show_social_instagram')
  );

  const linkedinLink = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_general_show_social_linkedin')
  );

  const twitterLink = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_general_show_social_twitter')
  );

  const youtubeLink = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_general_show_social_youtube')
  );

  const whatappLink = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_general_show_social_whatsapp')
  );

  // useEffect( () => {
  //   console.log("forDebug", "showSocial", showSocial)
  //   console.log("forDebug", "facebookLink", facebookLink)
  //   console.log("forDebug", "builderSettings", builderSettings)
  // })

  return (
    <>
      {
        props.showContactSection ?
          <section id={'contact'} className="bg-master-lightest py-5">
            <ViewPort
              parentClassName={'container-fluid mb-5'}
              skeletonItem={ <ContactSkeleton showContactForm={props.showContactForm}/> }
            >
                <div className="container">
                  <div className="row">
                    <ContactLocations
                      locations={props.locations}
                      showContactForm={showContactForm}
                      contactFormTitle={props.contactFormTitle}
                      contactPhoneOverride={contactPhoneOverride}
                      contactEmailOverride={contactEmailOverride}
                      contactTitleOverride={contactTitleOverride}
                      contactPhone={contactPhone}
                      contactEmail={contactEmail}
                    />
                    {
                      isSuccess ?
                          <>
                            <div className={"col-12 col-sm-12 col-md-6 ps-md-5"}>
                              <Alert severity="success" sx={{mb: "20px"}}>
                                Thank you for reaching out!
                                <br />
                                Your message has been successfully received and will get back to you as soon as possible.
                              </Alert>
                            </div>
                          </>
                          :
                          <>
                        {
                          props.showContactForm ?
                            <ContactForm
                              captchaSiteKey={props.captchaSiteKey}
                              showDestination={props.showDestination}
                              searchbarRadius={props.searchbarRadius}
                              insideSingleProperty={false} 
                              />
                            : ''
                        }
                          </>
                    }
                    {
                      props.contactHtml != undefined ?
                        <div dangerouslySetInnerHTML={{ __html: props.contactHtml.contact_html }}/>
                        :
                        ""
                    }
                  </div>
                </div>
                {
                  showSocial ?
                      <>
                        <div className={'container m-t-50'}>
                          <div className="text-left font-arial sm-text-center">
                            <ul className="no-style fs-12 no-padding pull-right sm-pull-reset xs-m-t-20">
                              {
                                Checkers.isValidString(facebookLink) ?
                                    <>
                                      <li className="inline no-padding">
                                        <a href={facebookLink}
                                           target="_blank"
                                           rel="noreferrer"
                                           className="custom_font_color text-master p-l-30 sm-p-r-10 sm-p-l-10"
                                           aria-label="Facebook Page">
                                          <FontAwesomeIcon icon={faFacebookF} className="fs-36" size="2xl" />
                                          <span className="hidden">Facebook</span>
                                        </a>
                                      </li>
                                    </>
                                    :
                                    <>
                                    </>
                              }
                              {
                                Checkers.isValidString(instagramLink) ?
                                    <>
                                      <li className="inline no-padding">
                                        <a href={instagramLink}
                                           target="_blank"
                                           rel="noreferrer"
                                           className="custom_font_color text-master p-l-30 sm-p-r-10 sm-p-l-10"
                                           aria-label="Instagram Page">
                                          <FontAwesomeIcon icon={faInstagram} className="fs-36" size="xl"/>
                                          <span className="hidden">Instagram</span>
                                        </a>
                                      </li>
                                    </>
                                    :
                                    <>
                                    </>
                              }
                              {
                                Checkers.isValidString(youtubeLink) ?
                                    <>
                                      <li className="inline no-padding">
                                        <a href={youtubeLink} target="_blank"
                                           rel="noreferrer"
                                           className="custom_font_color text-master p-l-30 sm-p-r-10 sm-p-l-10"
                                           aria-label="YouTube Page">
                                          <FontAwesomeIcon icon={faYoutube} className="fs-36" size="xl"/>
                                          <span className="hidden">YouTube</span>
                                        </a>
                                      </li>
                                    </>
                                    :
                                    <>
                                    </>
                              }
                              {
                                Checkers.isValidString(twitterLink) ?
                                    <>
                                      <li className="inline no-padding">
                                        <a href={twitterLink}
                                           target="_blank"
                                           rel="noreferrer"
                                           className="custom_font_color text-master p-l-30 sm-p-r-10 sm-p-l-10"
                                           aria-label="Twitter Page">
                                          <FontAwesomeIcon icon={faTwitter} className="fs-36" size="xl"/>
                                          <span className="hidden">Twitter</span>
                                        </a>
                                      </li>
                                    </>
                                    :
                                    <>
                                    </>
                              }
                              {
                                Checkers.isValidString(whatappLink) ?
                                    <>
                                      <li className="inline no-padding">
                                        <a href={whatappLink}
                                           target="_blank"
                                           rel="noreferrer"
                                           className="custom_font_color text-master p-l-30 sm-p-r-10 sm-p-l-10"
                                           aria-label="Pinterest Page">
                                          <FontAwesomeIcon icon={faWhatsapp} className="fs-36" size="xl"/>
                                          <span className="hidden">Pinterest</span>
                                        </a>
                                      </li>
                                    </>
                                    :
                                    <>
                                    </>
                              }
                              {
                                Checkers.isValidString(linkedinLink) ?
                                    <>
                                      <li className="inline no-padding">
                                        <a href={linkedinLink}
                                           target="_blank"
                                           rel="noreferrer"
                                           className="custom_font_color text-master p-l-30 sm-p-r-10 sm-p-l-10"
                                           aria-label="LinkedIn Page">
                                          <FontAwesomeIcon icon={faLinkedinIn} className="fs-36" size="xl"/>
                                          <span className="hidden">LinkedIn</span>
                                        </a>
                                      </li>
                                    </>
                                    :
                                    <>
                                    </>
                              }

                            </ul>
                            <div className="clearfix m-b-20"></div>
                          </div>
                        </div>
                      </>
                      :
                      <>
                      </>

                }
            </ViewPort>
          </section>
          : <></>
      }
    </>
  )
}
